export function updateHead(page) {
  console.log(`🍤 ~ updateHead ~ page:`, page)
  const url = "https://app.openhousechicago.org" + page.path;
  useHead({
    title: page.title,
    meta: [
      {
        name: 'description',
        content: page.description || '',
      },
      {
        property: 'twitter:title',
        content: page.title || '',
      },
      {
        property: 'twitter:description',
        content: page.description || '',
      },
      {
        property: 'og:title',
        content: page.title || '',
      },
      {
        property: 'og:description',
        content: page.description || '',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:url',
        content: url,
      },
      {
        property: 'og:locale',
        content: 'en_US',
      },
      {
        property: 'og:image',
        content: page.image || '',
      },
    ],
  });
}
