<script setup>
// Composables
import { updateHead } from '@/composables/helpers/updateHead';
import { getEnv } from '~/composables/helpers/getEnv';

// Variables
const iframe = ref(null);
const iframeUrl = getEnv('ohcIframeUrl');
const { path } = useRoute();
const route = useRoute();
const router = useRouter();
const height = ref('calc(100vh - var(--header-height))');

const cleanPath = computed(() => {
    // Make sure it's just the path
    const path = route.fullPath
    let cleanPath = path.replace('/open-house-chicago/', '');
    cleanPath = cleanPath.replace('/open-house-chicago', '');
    cleanPath = cleanPath.replace('open-house-chicago/', '');

    // Make sure the path doesn't have a leading slash
    if (cleanPath.startsWith('/')) {
        cleanPath = cleanPath.substring(1);
    }
    // // Remove query params
    //if (cleanPath.includes('?')) {
    //    cleanPath = cleanPath.split('?')[0];
    //}
    //
    // Remove query hash
    if (cleanPath.includes('#')) {
        cleanPath = cleanPath.split('#')[0];
    }

    console.info(cleanPath)

    return cleanPath;
});


// Assemble the URL
const url = computed(() => {
    return iframeUrl + '/' + cleanPath.value;
});

// Methods
const updateIframeHeight = (newHeight = 500) => {
    height.value = `${newHeight}px`;
};

const searchParamsToDictionary = (searchParams) => {
    let params = new URLSearchParams(searchParams);
    let dict = {};
    for (let [key, value] of params.entries()) { dict[key] = value; }
    return dict;
}

const handleNavigateTo = async (href) => {
    // Check if the URL is internal or external
    const internal = href.startsWith('https://www.architecture.org/' || 'https://architecture.org/')
    const app_link = href.startsWith('/iframe');

    const urlOverrides = {
        'about-ohc': '/open-house-chicago/about',
        about: '/about',
        'about-cac': '/about',
        'about/land-acknowledgment': '/about/land-acknowledgment',
        'about/contact-us': '/about/contact-us',
        memberships: '/join-and-support/membership',
        'join-give/join/membership': '/join-and-support/membership',
        'join-and-support/membership': '/join-and-support/membership',
        'city-tours': '/city-tours',
        'city-tours/river-cruise': '/city-tours/river-cruise',
        'city-tours/bus': '/city-tours?tour-type=Bus',
        'city-tours/walking': '/city-tours?tour-type=Walking',
        'city-tours/accessibility': '/city-tours/accessibility-and-safety',
        visit: '/visit-the-center',
        'visit/buy-center-tickets': '/visit-the-center/buy-center-tickets',
        exhibitions: '/visit-the-center/exhibitions',
        'programs-events': '/events-programs',
        'programs-events/youth': '/events-programs?category=Youth%2520%252B%2520Teen',
        connect: '/about/contact-us',
        'open-house-chicago/photo-competition-2024': '/open-house-chicago/photo-competition-2024'
    };
    
    // iFrame app links should just refresh the URL without any other work
    if (app_link) {
        const new_href = href.replace('/iframe', '/open-house-chicago')
        if (window?.history) { window.history.pushState(window.history.state, '', new_href) }
    }
    // Site Internal URLs should cause full navigation
    if (internal) {
        const url = new URL(href);
        await navigateTo({ path: url.pathname, query: searchParamsToDictionary(url.searchParams), hash: url.hash })
    }
};

const tweakSEO = async () => {
    if (import.meta.server) return;
    try {
        const data = await $fetch(`https://app.openhousechicago.org/api/seo?url=/${cleanPath.value}`);
        updateHead(data.response)
    } catch (error) {
        console.log(`🍤 ~ error:`, error)
    }
};

const removeEventListeners = () => {
    window.removeEventListener('message', handleMessages, '*');
};

const handleMessages = async (event) => {
    const { data } = event;
    if (data && data.type) {
        switch (data.type) {
            case 'updateHeight':
                updateIframeHeight(data.height);
                break;
            case 'navigateTo':
                await handleNavigateTo(data.href);
                break;
            case 'tweakSEO':
                await tweakSEO();
                break;
        }
    }
};

// SEO Hack
const { data: SEO, error } = await useAsyncData('seo-pages' + cleanPath.value, () => $fetch(`https://app.openhousechicago.org/api/seo?url=/${cleanPath.value}`))
try {
    updateHead(SEO.value.response);
} catch (error) {
    console.log(`🍤 ~ error:`, error)
}


// Life cycle
onMounted(() => {
    window.addEventListener('message', handleMessages, '*');
});

onBeforeUnmount(() => {
    removeEventListeners();
});

</script>

<template>
    <div class="page-wrapper" :style="`--iframe-height:${height};`">
        <div id="ohc-iframe-wrapper">
            <iframe id="iframe" :src="url" name="iframe" ref="iframe" allow="fullscreen" />
        </div>
        <!-- <GlobalFooter /> -->
    </div>
</template>

<style scoped lang="scss">
#ohc-iframe-wrapper {
    --header-height: 55px;
    width: 100%;

    @media (min-width: $mobile) {
        --header-height: 88px;
    }

    #iframe {
        width: 100%;
        padding-bottom: 0;
        position: fixed;
        top: var(--header-height);
        height: calc(100vh - var(--header-height));
        background: white;
        z-index: 10;
    }
}
</style>
